<template>
    <div>
    <alertMessage :DialogMsg="alertMessage.alertMessageShow" :DialogImgColor="alertMessage.alertImgColor" :DialogImg="alertMessage.alertImg" :DialogHeaderText="alertMessage.alertHeaderText" :DialogText="alertMessage.alertText"></alertMessage>
    <v-dialog v-model="dialog" persistent  :max-width="maxWidth">
        <v-card>
            <v-card-title>
                <span class="headline" style="color:#1976d2">{{ formTitle }}</span>
            </v-card-title>
            <v-alert
                    v-model="alertDialog"
                    color="error"
                    icon="warning"
                    outlined
                    dismissible
            >
                {{ alertText }}
            </v-alert>
            <hr />
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout row wrap >
                        <v-flex :class="field.sizeField" sm v-for="(field, index) in dialogFields" :key="index" >
                            <v-text-field :ref="editedItem[field.model]" :rules="field.rulesField" :counter="field.counterField" :mask="field.maskField"
                                          :readonly="field.readonly" v-model="editedItem[field.model]" :label="field.label" v-if="drawComponent && field.typeField=='text' &&field.dialog.indexOf(formTitle)!=-1"></v-text-field>
                            <v-text-field :readonly="field.readonly" v-model="editedItem[field.model]" :label="field.label" type="password" v-if="field.typeField=='pass' && field.dialog.indexOf(formTitle)!=-1"></v-text-field>
                            <v-text-field :readonly="field.readonly" v-model="editedItem[field.model]" :label="field.label" :rules="field.rulesField"  :counter="field.counterField" :mask="field.maskField" type="number" v-if="field.typeField=='number' && field.dialog.indexOf(formTitle)!=-1"></v-text-field>
                            <v-text-field :readonly="field.readonly" v-model="editedItem[field.model]" :label="field.label" :rules="emailRules"  :counter="field.counterField" :mask="field.maskField" type="text" v-if="field.typeField=='email' && field.dialog.indexOf(formTitle)!=-1"></v-text-field>
                            <v-checkbox color="info" :label="field.label" v-model="editedItem[field.model]" v-if="field.typeField=='check' && field.dialog.indexOf(formTitle)!=-1"></v-checkbox>
                            <dadata :FullAddressIN = "addressSelected" v-if="drawComponent && field.typeField=='dadata' && field.dialog.indexOf(formTitle)!=-1"></dadata>
                            <bnkseek :BnkId="bnkID" v-if="drawComponent && field.typeField=='bnkseek' && field.dialog.indexOf(formTitle)!=-1"></bnkseek>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small dark color="primary" @click.native="close">Отмена</v-btn>
                <v-btn small dark color="primary" @click.native="save">Сохранить</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-alert
            v-model="alert"
            color="error"
            icon="priority_high"
            outlined
            dismissible
    >
        {{ alertText }}
    </v-alert>

    <v-dialog v-model="dialogJob" persistent  max-width="500px">
        <v-card>
            <v-card-title>
                <span class="headline" style="color:#1976d2">Краткая информация о процессе</span>
            </v-card-title>
            <v-alert
                    v-model="alertDialog"
                    color="info"
                    icon="info"
                    outlined
                    dismissible
            >
                {{ alertText }}
            </v-alert>
            <hr />
            <v-card-text>
                <p></p>
                <p>Последнее изменение: {{ joblastModifiedBy }}</p>
                <p>Имя процесса: {{ jobname }}</p>
                <p>Следующий запуск: {{ jobnextRunAt }} (Время местное)</p>
                <p>Приоритет: {{ jobpriority }}</p>
                <p>Повторять: {{ jobrepeatInterval }}</p>
                <p>Часовой пояс: {{ jobrepeatTimezone }}</p>
                <p>------------------------------</p>
                <p>Количество ошибок: {{ jobfailCount }}</p>
                <p>Ошибка, описание: {{ jobfailReason }}</p>
                <p>Дата и время последней ошибки: {{ jobfailedAt }}</p>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small color="primary" @click.native="close">Закрыть</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDel" persistent  max-width="500px">
        <v-card>
            <v-card-title>
                <span class="headline" style="color:#1976d2">Удаление</span>
            </v-card-title>
            <v-alert
                    v-model="alertDialog"
                    color="error"
                    icon="warning"
                    outlined
                    dismissible
            >
                {{ alertText }}
            </v-alert>
            <hr />
            <v-card-text>
                <span>Удалить процесс - {{ typeof selected !== "undefined" ? selected.UUID + ', ' + selected.strSchoolName + ', ' + selected.strJobName : '' }}?</span>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small color="primary" @click.native="close">Отмена</v-btn>
                <v-btn small color="primary" @click.native="doDelete">Удалить</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="dialogPause" persistent  max-width="500px">
        <v-card>
            <v-card-title>
                <span class="headline" style="color:#1976d2">Остановка процесса</span>
            </v-card-title>
            <v-alert
                    v-model="alertDialog"
                    color="error"
                    icon="warning"
                    outlined
                    dismissible
            >
                {{ alertText }}
            </v-alert>
            <hr />
            <v-card-text>
                <span >Остановить процесс - {{ typeof selected !== "undefined" ? selected.UUID + ', ' + selected.strSchoolName + ', ' + selected.strJobName : '' }}?</span>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small color="primary" @click.native="close">Отмена</v-btn>
                <v-btn small color="primary" @click.native="doPauseJobByUID">Остановить</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="dialogResume" persistent  max-width="500px">
        <v-card>
            <v-card-title>
                <span class="headline" style="color:#1976d2">Остановка процесса</span>
            </v-card-title>
            <v-alert
                    v-model="alertDialog"
                    color="error"
                    icon="warning"
                    outlined
                    dismissible
            >
                {{ alertText }}
            </v-alert>
            <hr />
            <v-card-text>
                <span >Запустить процесс - {{ typeof selected !== "undefined" ? selected.UUID + ', ' + selected.strSchoolName + ', ' + selected.strJobName : '' }}?</span>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small color="primary" @click.native="close">Отмена</v-btn>
                <v-btn small color="primary" @click.native="doResumeJobByUID">Запустить</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
              
    <v-container class="mainGridConteiner" :style="maxGridWidth">
        <v-layout justify-center >
            <v-card class="pa-3">
            <dx-data-grid :ref="gridRefName"
                            id="gridContainerProj"
                            :data-source="DataReturn"
                            :show-borders="true"
                            :hover-state-enabled="true"
                            :allow-column-reordering="allowColumnReordering"
                            :allow-column-resizing="allowColumnResizing"
                            :column-auto-width="columnAutoWidth"
                            :column-hiding-enabled="columnHidingEnabled"
                            :word-wrap-enabled="true"
                            @toolbar-preparing="onToolbarPreparing($event)"
                            key="id"
                            @selection-changed="onSelectionChanged"
                            :style="{ height: windowHeight-180 + 'px' }"
                >
                <DxScrolling mode="virtual"/>
                <DxGrouping 
                    :auto-expand-all="false"
                />
                <dx-paging :page-size="pageSize"/>
                <dx-pager
                    :show-page-size-selector="showPageSizeSelector"
                    :allowed-page-sizes="allowedPageSizes"
                    :show-info="true"
                />

                <dx-selection mode="single" />
                <dx-group-panel :visible="groupPanelVisible" />
                <dx-load-panel :enabled="false" :visible="false" />
                <dx-filter-row :visible="filterRowVisible" />
                <dx-header-filter :visible="headerFilterVisible" />
                <dx-search-panel :visible="searchVisible" :width="searchWidth" placeholder="Поиск..." />

                <dx-column v-for="(col, index) in columnSettingsIN" :key="index+'-column'" :width="col.colWidth" :data-field="col.colDataField" :caption="col.colCaption" :sort-order="col.sortOrder" :group-index="col.groupindex" :visible.sync="col.visible" />
                

                <dx-summary v-if="summaryVisible" >
                    <dx-group-item v-for="(group, indx) in groupSummarySettingsIN" :key="indx + '-group'"
                                    :show-in-group-footer="group.showInGroupFooter"
                                    :align-by-column="group.alignByColumn"
                                    :column="group.columnGroup"
                                    :summary-type="group.summaryType"
                                    :display-format="group.displayFormat"
                                    :value-format="group.valueFormat"
                    />
                </dx-summary>
                <dx-sort-by-group-summary-info summary-item="count" />
                <div
                                slot="vProgressVuetify"
                                
                        >
                            <div>
                                <p style="margin: 0 4.5em 0em 0em;"></p>
                                <v-progress-circular
                                  :size="40"
                                  color="indigo"
                                  indeterminate
                                  v-if="loadingF"
                                ></v-progress-circular>
                            </div>
                        </div>
                        <div
                                slot="titleGroupVuetify"
                                
                        >
                            <div>
                                <v-chip outlined color="primary">
                                    <v-icon left>{{headerIcon}}</v-icon>{{headerText}}
                                </v-chip>
                                <div>
                                <p style="padding: 0 15em 0em 0;"></p>
                                </div>
                            </div>
                        </div>
                        <div
                                slot="GroupButtonVuetify"
                                
                        >
                            <div>
                                    <v-tooltip bottom  :key="tooltipKey0" class="pa-4">
                                         <template v-slot:activator="{ on }">
                                            <v-btn
                                                    slot="activator"
                                                    @click="refreshData()"
                                                    color="indigo"
                                                    dark
                                                    small
                                                    right
                                                    fab
                                                    v-if="btnRefresh"
                                                    v-on="on"
                                                    class="mr-2"
                                                    
                                            >
                                                <v-icon>mdi-timer-refresh-outline</v-icon>
                                            </v-btn>
                                         </template>
                                         <span>Обновить</span>
                                    </v-tooltip>
                                    <v-tooltip bottom :key="tooltipKey1">
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                    slot="activator"
                                                    @click="doCheck()"
                                                    color="indigo"
                                                    dark
                                                    small
                                                    right
                                                    fab
                                                    v-if="BtnAdd"
                                                    v-on="on"
                                                    class="mr-5"
                                            >
                                                <v-icon>mdi-beaker-check-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Посмотреть состояние</span>
                                    </v-tooltip>
                                    <v-tooltip bottom :key="tooltipKey2">
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                    slot="activator"
                                                    @click="pauseJobByUID()"
                                                    color="indigo"
                                                    dark
                                                    small
                                                    right
                                                    fab
                                                    v-if="BtnAdd"
                                                    v-on="on"
                                                    class="mr-2"
                                            >
                                                <v-icon>mdi-timer-pause-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Приостановить работу</span>
                                    </v-tooltip>
                                    <v-tooltip bottom :key="tooltipKey3">
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                    slot="activator"
                                                    @click="resumeJobByUID()"
                                                    color="indigo"
                                                    dark
                                                    small
                                                    right
                                                    fab
                                                    v-if="BtnEdit"
                                                    v-on="on"
                                                    class="mr-2"
                                                    
                                            >
                                                <v-icon>mdi-timer-play-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Восстановить работу</span>
                                    </v-tooltip>
                                    <v-tooltip bottom :key="tooltipKey4">
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                    slot="activator"
                                                    @click="copyOneTimeJobByUID()"
                                                    color="indigo"
                                                    dark
                                                    small
                                                    right
                                                    fab
                                                    v-if="BtnEdit"
                                                    v-on="on"
                                                    class="mr-5"
                                            >
                                                <v-icon>mdi mdi-timer-star-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Разовая задача</span>
                                    </v-tooltip>
                                    <v-tooltip bottom :key="tooltipKey5">
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                    slot="activator"
                                                    @click="editJobByUID()"
                                                    color="indigo"
                                                    dark
                                                    small
                                                    right
                                                    fab
                                                    v-if="BtnEdit"
                                                    v-on="on"
                                                    class="mr-2"
                                                    
                                            >
                                                <v-icon>mdi-timer-edit-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Редактировать</span>
                                    </v-tooltip>
                                    <v-tooltip bottom :key="tooltipKey6">
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                    slot="activator"
                                                    @click="copyJobByUID()"
                                                    color="indigo"
                                                    dark
                                                    small
                                                    right
                                                    fab
                                                    v-if="BtnEdit"
                                                    v-on="on"
                                                    class="mr-2"
                                            >
                                                <v-icon>mdi-timer-sync-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Копировать</span>
                                    </v-tooltip>
                                    <v-tooltip bottom :key="tooltipKey7">
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                    slot="activator"
                                                    @click="deleteItem()"
                                                    color="indigo"
                                                    dark
                                                    small
                                                    right
                                                    fab
                                                    v-if="BtnDelete"
                                                    v-on="on"
                                            >
                                                <v-icon>mdi-timer-remove-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Удалить</span>
                                    </v-tooltip>
                                    
                            </div>
                        </div>
            </dx-data-grid >
             </v-card>
        </v-layout>
    </v-container>
    <v-dialog persistent
      v-model="editdialog" 
      width="90%" 
      max-width="1500px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
        <v-card color="white">
        <component :is="currentForm" key="formKey" @close="closeDialog"></component>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
    import Vue from 'vue'
    //import axios from 'axios'
    
    import 'material-design-icons-iconfont/dist/material-design-icons.css'
    import 'vuetify/dist/vuetify.min.css'

    import axios from 'axios'
    import store from '../store/store.js'
    import date from 'date-and-time';

    import alertMessage from "@/components/alertMessage.vue"

    import UPcreateGC_Sheet_Deals_Exp_Edit_Copy from '../views/createGC_Sheet_Deals_Exp_Edit_Copy.vue'
    import UPcreateGC_Sheet_Payments_Exp_Edit_Copy from '../views/createGC_Sheet_Payments_Exp_Edit_Copy.vue'
    import UPcreateGC_Sheet_Users_Exp_Edit_Copy from '../views/createGC_Sheet_Users_Exp_Edit_Copy.vue'
    import UPcreateBZ365_Sheet_Webinar_Exp_Edit_Copy from '../views/createBZ365_Sheet_Webinar_Exp_Edit_Copy.vue'                            
    import UPcreateGC_PG_DB_Deals_Exp_Edit_Copy from '../views/createGC_PG_DB_Deals_Exp_Edit_Copy.vue'                            
    import UPcreateGC_PG_DB_Payments_Exp_Edit_Copy from '../views/createGC_PG_DB_Payments_Exp_Edit_Copy.vue'                            
    import UPcreateGC_PG_DB_Users_Exp_Edit_Copy from '../views/createGC_PG_DB_Users_Exp_Edit_Copy.vue'                            
    import UPcreateBZ365_PG_DB_Webinar_Exp_Edit_Copy from '../views/createBZ365_PG_DB_Webinar_Exp_Edit_Copy.vue'                            
    import UPcreateFX_PG_Run_Edit_Copy from '../views/createFX_PG_Run_Edit_Copy.vue'  
    import UPcreatePG_Sheet_Exp_Edit_Copy from '../views/createPG_Sheet_Exp_Edit_Copy.vue'  
    import UPcreatePBX_PG_DB_Calls_Exp_Edit_Copy from '../views/createPBX_PG_DB_Calls_Exp_Edit_Copy.vue'
    import UPcreateGC_PG_DB_MailList_Exp_Edit_Copy from '../views/createGC_PG_DB_MailList_Exp_Edit_Copy.vue'
    import UPcreateYANDEX_PG_DB_Direct_Exp_Edit_Copy from '../views/createYANDEX_PG_DB_Direct_Exp_Edit_Copy.vue'
    import UPcreateVK1_PG_DB_Ads_Exp_Edit_Copy from '../views/createVK1_PG_DB_Ads_Exp_Edit_Copy.vue'
    import UPcreateVK2_PG_DB_Ads_Exp_Edit_Copy from '../views/createVK2_PG_DB_Ads_Exp_Edit_Copy.vue'
    import UPcreateGADS_PG_DB_Exp_Edit_Copy from '../views/createGADS_PG_DB_Exp_Edit_Copy.vue'
    import UPcreatePG_Sheet_Exp_Sql from '../views/createPG_Sheet_Exp_Sql.vue'
    import UPcreateGC_PG_DB_UserActive_Exp from '../views/createGC_PG_DB_UserActive_Exp.vue'
    import UPcreateAmo_PG_DB_Contacts_Exp_Edit_Copy from '../views/createAmo_PG_DB_Contacts_Exp_Edit_Copy.vue'
    import UPcreateAmo_PG_DB_Leads_Exp_Edit_Copy from '../views/createAmo_PG_DB_Leads_Exp_Edit_Copy.vue'
    import UPcreateGC_PG_DB_UsersBuilder_Exp from '../views/createGC_PG_DB_UsersBuilder_Exp.vue'

   
   import {
    DxDataGrid,
    DxColumn,
    DxSelection,
    DxSummary,
    DxGroupItem,
    DxGroupPanel,
    DxGrouping,
    DxHeaderFilter,
    DxSearchPanel,
    DxSortByGroupSummaryInfo,
    DxFilterRow,
    DxLoadPanel,
    DxPaging,
    DxScrolling,
    DxPager
} from 'devextreme-vue/data-grid';


import 'devextreme/dist/css/dx.common.css';

    export default {
        name: "sample",
        components: {
        DxDataGrid,
        DxColumn,
        DxSelection,
        DxSummary,
        DxGroupItem,
        DxGroupPanel,
        DxGrouping,
        DxHeaderFilter,
        DxSortByGroupSummaryInfo,
        DxSearchPanel,
        DxFilterRow,
        DxLoadPanel,
        DxPaging,
        DxPager,
        DxScrolling,
        alertMessage,
        UPcreateGC_Sheet_Deals_Exp_Edit_Copy,
        UPcreateGC_Sheet_Payments_Exp_Edit_Copy,
        UPcreateGC_Sheet_Users_Exp_Edit_Copy, 	
        UPcreateBZ365_Sheet_Webinar_Exp_Edit_Copy,
        UPcreateGC_PG_DB_Deals_Exp_Edit_Copy, 	
        UPcreateGC_PG_DB_Payments_Exp_Edit_Copy, 	
        UPcreateGC_PG_DB_Users_Exp_Edit_Copy, 	
        UPcreateBZ365_PG_DB_Webinar_Exp_Edit_Copy,
        UPcreateFX_PG_Run_Edit_Copy,				
        UPcreatePG_Sheet_Exp_Edit_Copy, 			
        UPcreatePBX_PG_DB_Calls_Exp_Edit_Copy, 	
        UPcreateGC_PG_DB_MailList_Exp_Edit_Copy, 	
        UPcreateYANDEX_PG_DB_Direct_Exp_Edit_Copy,
        UPcreateVK1_PG_DB_Ads_Exp_Edit_Copy, 		
        UPcreateVK2_PG_DB_Ads_Exp_Edit_Copy, 		
        UPcreateGADS_PG_DB_Exp_Edit_Copy, 		
        UPcreatePG_Sheet_Exp_Sql,				
        UPcreateGC_PG_DB_UserActive_Exp, 			
        UPcreateAmo_PG_DB_Contacts_Exp_Edit_Copy, 
        UPcreateAmo_PG_DB_Leads_Exp_Edit_Copy, 	
        UPcreateGC_PG_DB_UsersBuilder_Exp
    },
    props: {
        GridRefName: {
            default () {
                return 'dataGrid'
            }
        },
        AllowColumnReordering: {
            default () {
                return true
            }
        },
        AllowColumnResizing: {
            default () {
                return true
            }
        },
        ColumnAutoWidth: {
            default () {
                return true
            }
        },
        ColumnHidingEnabled: {
            default () {
                return true
            }
        },
        PageSize: {
            default () {
                return 5
            }
        },
        ShowPageSizeSelector: {
            default () {
                return false
            }
        },
        AllowedPageSizes: {
            default () {
                return [ 5 ]
            }
        },
        HeaderText: {
            default () {
                return 'Вам нужно передать заголовок через Props'
            }
        },
        HeaderIcon: {
            default () {
                return ''
            }
        },
        ColumnSettingsIN: {
            default () {
                return []
            }
        },
        SummaryVisible: {
            default () {
                return false
            }
        },
        GroupSummarySettingsIN: {
            default () {
                return []
            }
        },
        GroupPanelVisible: {
            default () {
                return false
            }
        },
        FilterRowVisible: {
            default () {
                return false
            }
        },
        HeaderFilterVisible: {
            default () {
                return false
            }
        },
        SearchVisible: {
            default () {
                return false
            }
        },
        SearchWidth: {
            default () {
                return 300
            }
        },
        BtnRefresh: {
            default () {
                return false
            }
        },
        BtnAdd: {
            default () {
                return false
            }
        },
        BtnEdit: {
            default () {
                return false
            }
        },
        BtnDelete: {
            default () {
                return false
            }
        },
        BtnShipper: {
            default () {
                return false
            }
        },
        MaxWidth: {
            default () {
                return "600px"
            }
        },
        MaxGridWidth: {
            default () {
                return ""
            }
        },
        EditedItem: {
            default () {
                return [{
                    id: ''
                }]
            }
        },
        DefaultItem: {
            default () {
                return [{
                    id: ''
                }]
            }
        },
        EditedFields: {
            default () {
                return [{
                    model: ''
                }]
            }
        },
        AddFields: {
            default () {
                return [{
                    model: ''
                }]
            }
        },
        EndPoint: {
            default () {
                return ''
            }
        },
        ParentParams: {
            default () {
                return null
            }
        }
    },
        data() {
            return {
            windowHeight: window.innerHeight,
            maxGridWidth: '',
            allowColumnReordering: this.AllowColumnReordering,
            allowColumnResizing: this.AllowColumnResizing,
            columnAutoWidth: this.ColumnAutoWidth,
            columnHidingEnabled: this.ColumnHidingEnabled,
            pageSize: this.PageSize,
            showPageSizeSelector: this.ShowPageSizeSelector,
            allowedPageSizes: this.AllowedPageSizes,
            drawComponent: true,
            gridRefName: this.GridRefName,
            expanded: true,
            alert: false,
            alertDialog: false,
            alertText: '',
            dialog: false,
            dialogJob: false,
            dialogDel: false,
            dialogPause: false,
            dialogResume: false,
            dialogSetShipper: false,
            headerText: this.HeaderText,
            headerIcon: this.HeaderIcon,
            columnSettingsIN: this.ColumnSettingsIN,
            summaryVisible: this.SummaryVisible,
            groupSummarySettingsIN: this.GroupSummarySettingsIN,
            groupPanelVisible: this.GroupPanelVisible,
            filterRowVisible: this.FilterRowVisible,
            headerFilterVisible: this.HeaderFilterVisible,
            searchVisible: this.SearchVisible,
            searchWidth: this.SearchWidth,
            btnRefresh: this.BtnRefresh,
            btnAdd: this.BtnAdd,
            btnEdit: this.BtnEdit,
            btnDelete: this.BtnDelete,
            btnShipper: this.BtnShipper,
            loading: false,
            selected: '',
            dataItems: '',
            editedIndex: -1,
            editedItem: {},
            defaultItem: {},
            editedFields: [],
            addFields: [],
            dialogFields: [],
            endPoint: '',
            parentParams: this.ParentParams,
            maxWidth: "",
            bnkID: '',
            addressSelected: '',
            alertMessage: {alertMessageShow: false, alertImgColor: "error", alertImg: "error_outline", alertHeaderText: 'Ошибка', alertText: 'Ошибка'},
            shipperMailShow: true,
            emailRules: [ 
                            v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail введен не верно'
                        ],
            joblastModifiedBy: '',
            jobname: '',
            jobnextRunAt: '',
            jobpriority: '',
            jobrepeatInterval: '',
            jobrepeatTimezone: '',
            jobfailCount: '',
            jobfailReason: '',
            jobfailedAt: '',
            editdialog: false,
            currentForm: null,
            formKey: 0,
            tooltipKey0: 0,
            tooltipKey1: 0,
            tooltipKey2: 0,
            tooltipKey3: 0,
            tooltipKey4: 0,
            tooltipKey5: 0,
            tooltipKey6: 0,
            tooltipKey7: 0,
            
        }
    },
    created() {

    },
    beforeMount() {
        this.gridRefName = this.GridRefName
        this.allowColumnReordering = this.AllowColumnReordering
        this.allowColumnResizing = this.AllowColumnResizing
        this.columnAutoWidth = this.ColumnAutoWidth
        this.columnHidingEnabled = this.ColumnHidingEnabled
        this.pageSize = this.PageSize
        this.showPageSizeSelector = this.ShowPageSizeSelector
        this.allowedPageSizes = this.AllowedPageSizes
        this.headerText = this.HeaderText
        this.headerIcon = this.HeaderIcon
        this.columnSettingsIN = this.ColumnSettingsIN
        this.groupSummarySettingsIN = this.GroupSummarySettingsIN
        this.groupPanelVisible = this.GroupPanelVisible
        this.filterRowVisible = this.FilterRowVisible
        this.headerFilterVisible = this.HeaderFilterVisible
        this.searchVisible = this.SearchVisible
        this.searchWidth = this.SearchWidth
        this.summaryVisible = this.SummaryVisible
        this.editedItem = this.EditedItem
        this.defaultItem = this.DefaultItem
        this.editedFields = this.EditedFields
        this.addFields = this.AddFields
        this.endPoint = this.EndPoint
        this.parentParams = this.ParentParams
        this.btnRefresh = this.BtnRefresh
        this.btnAdd = this.BtnAdd
        this.btnEdit = this.BtnEdit
        this.btnDelete = this.BtnDelete
        this.btnShipper = this.BtnShipper
        this.maxWidth = this.MaxWidth
        this.maxGridWidth = this.MaxGridWidth

        
    },
    methods: {
        reset: function() {
            var vm = this;
            vm.drawComponent = false;
            Vue.nextTick(function() {
                vm.drawComponent = true;
            });
        },
        closeDialog() {
            this.editdialog = false;
            this.currentForm = null
            this.refreshData();
            this.resetTooltip()
        },
        resetTooltip() {
            // Увеличиваем ключ, чтобы принудительно пересоздать компонент тултипа
            this.tooltipKey0 += 1;
            this.tooltipKey1 += 1;
            this.tooltipKey2 += 1;
            this.tooltipKey3 += 1;
            this.tooltipKey4 += 1;
            this.tooltipKey5 += 1;
            this.tooltipKey6 += 1;
            this.tooltipKey7 += 1;
        },
        doAlertMessage(Show, ImgColor, Img, HeaderText, MsgText) {
            this.alertMessage.alertMessageShow = Show
            if(ImgColor != ''){ this.alertMessage.alertImgColor = ImgColor }
            if(Img != ''){ this.alertMessage.alertImg = Img }
            if(HeaderText != ''){ this.alertMessage.alertHeaderText = HeaderText }
            if(MsgText != ''){ this.alertMessage.alertText = MsgText }
        },
        authUsersGet() {
            this.$store.dispatch('loadAuthUsers')
        },
        onToolbarPreparing(e) {
            e.toolbarOptions.items.unshift({
                    location: 'after',
                    template: 'vProgressVuetify'
                }, {
                location: 'after',
                template: 'GroupButtonVuetify'
                }, {
                location: 'before',
                template: 'titleGroupVuetify'
            });
        },
        refreshData(){
            const settingCronProjectUUID = {
                strCronProjectUUID: this.$store.getters.CronProjectUUID,
            }
            this.$store.dispatch('loadjobProjects', settingCronProjectUUID)
            //this.dataItems = this.$store.getters.settings
            
        },
        isValidValue(value) {
            return value !== undefined && value !== null && value !== '';
        },
        addItem(){
               
            
                   
        },
        onSelectionChanged({ selectedRowsData }) {
            this.selected = selectedRowsData[0]
            //console.log(this.selected)
            if(this.selected !== undefined) {
                /* const setting = {
                    projectId: this.selected.id
                }  */
                //this.$store.commit('SetUserSettingsId', this.selected.id)
                //this.$store.commit('SetUserSettingsName', this.selected.strShortName)
                //this.$store.dispatch('loadAdminUsers',setting)
                //console.log(this.selected)
            }
            //console.log(this.selected)
            
        },
        close () {
            this.dialog = false
            this.dialogJob = false
            this.dialogDel = false
            this.dialogPause = false
            this.dialogResume = false
            this.dialogChangePass = false
            this.alertDialog = false
            this.dialogSetShipper = false
            this.reset()
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            this.dialogFields = this.addFields
            //this.bnkID = 0
            //this.addressSelected = ''

        },
        async doCheck(){
            if(this.isValidValue(this.selected)) {
                //console.log(this.selected)
                this.alertDialog = false
                //this.$root.$emit('setSelectBnkID', this.selected.bank)
                this.editedIndex = this.DataReturn.indexOf(this.selected)
                this.editedItem = Object.assign({}, this.selected)
                //console.log(this.editedItem.UUID)
                //this.dialogFields = this.editedFields
                //this.dialog = true
                let data = []
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': store.state.CurrentApiKey,
                        'jobUUID': this.editedItem.UUID,
                        'Content-Type': 'application/json'
                    },
                    url: store.state.urlCJ + 'checkJobFromList',
                    data: data

                })
                .then((response) => {
                    //console.log(response.data.nextRunAt)
                    let d = new Date(response.data.nextRunAt)
                    let dd = date.format(d, 'DD-MM-YYYY HH:mm:ss');
                    this.joblastModifiedBy = response.data.lastModifiedBy
                    this.jobname = response.data.name
                    this.jobnextRunAt = dd
                    this.jobpriority = response.data.priority
                    this.jobrepeatInterval = response.data.repeatInterval
                    this.jobrepeatTimezone = response.data.repeatTimezone
                    this.jobfailCount = response.data.failCount
                    this.jobfailReason = response.data.failReason
                    //console.log(response.data.failedAt)
                    if(response.data.failedAt !== undefined){
                        d = new Date(response.data.failedAt)
                        dd = date.format(d, 'DD-MM-YYYY HH:mm:ss');
                        this.jobfailedAt = dd
                    }
                    else{
                        this.jobfailedAt = ''
                    }

                    this.dialogJob = true
                })
                .catch((response) => {
                    //console.log(response)
                    let err = Object.assign({}, response)
                    console.log('error', err.response);
                                    
                    if(err.response.status == '488')
                    {
                        this.$router.push('/')
                    }
                });
            }
            else
            {
                //console.log(this.alert)
                this.doAlertMessage(true,'error','mdi-alert-circle-outline','Внимание','Для просмотра процесса, нужно выбрать запись в таблице.')
                //this.alertText = 'Для редактирования, нужно выбрать запись в таблице.'
                //this.alert = true
            }

        },
        deleteItem () {
            if(this.isValidValue(this.selected)) {
                this.editedIndex = this.DataReturn.indexOf(this.selected)
                this.editedItem = Object.assign({}, this.selected)
                
                this.dialogDel = true
            }
            else
            {
                //console.log(this.alert)
                this.doAlertMessage(true,'error','mdi-alert-circle-outline','Внимание','Для удаления, нужно выбрать запись в таблице.')
                //this.alertText = 'Для удаления, нужно выбрать запись в таблице.'
                //this.alert = true
            }
            //confirm('Удалить запись - ' + this.selected.name + '?') && this.doDelete(index)
        },
        async doDelete(){
            if(this.isValidValue(this.selected)) {
                //console.log(this.selected)
                this.dialogDel = false
                this.alertDialog = false
                //this.$root.$emit('setSelectBnkID', this.selected.bank)
                this.editedIndex = this.DataReturn.indexOf(this.selected)
                this.editedItem = Object.assign({}, this.selected)
                //this.editedItem.UUID)
                //this.dialogFields = this.editedFields
                //this.dialog = true
                let data = []
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': store.state.CurrentApiKey,
                        'jobUUID': this.editedItem.UUID,
                        'CronProjectUUID': store.state.CronProjectUUID,
                        'Content-Type': 'application/json'
                    },
                    url: store.state.urlCJ + 'deleteJobFromList',
                    data: data

                })
                .then((response) => {
                    //console.log(response)
                    if(response.data == "OK"){
                        this.doAlertMessage(true,'info','mdi-information-outline','Выполнено','Процесс удален')
                    }
                    else
                    {
                        this.doAlertMessage(true,'error','mdi-alert-circle-outline','Ошибка','Что то пошло не так, обратитесь к разработчику')
                    }
                    this.refreshData();
                })
                .catch((response) => {
                    //console.log(response)
                    let err = Object.assign({}, response)
                    console.log('error', err.response);
                                    
                    if(err.response.status == '488')
                    {
                        this.$router.push('/')
                    }
                });
            }
            else
            {
                //console.log(this.alert)
                this.doAlertMessage(true,'error','mdi-alert-circle-outline','Внимание','Для остановки процесса, нужно выбрать запись в таблице.')
                //this.alertText = 'Для редактирования, нужно выбрать запись в таблице.'
                //this.alert = true
            }

        },
        pauseJobByUID () {
            if(this.isValidValue(this.selected)) {
                this.editedIndex = this.DataReturn.indexOf(this.selected)
                this.editedItem = Object.assign({}, this.selected)
                //console.log(this.editedItem)
                if(this.editedItem.intJobActive == 'ДА'){
                    this.dialogPause = true
                }
                else
                {
                    this.doAlertMessage(true,'error','mdi-alert-circle-outline','Внимание','Для остановки процесса, нужно выбрать процесс который запущен!')
                }
            }
            else
            {
                //console.log(this.alert)
                this.doAlertMessage(true,'error','mdi-alert-circle-outline','Внимание','Для остановки процесса, нужно выбрать запись в таблице.')
                //this.alertText = 'Для удаления, нужно выбрать запись в таблице.'
                //this.alert = true
            }
        },
        doPauseJobByUID () {
            //console.log(this.selected)
            if(this.isValidValue(this.selected)) {
                //console.log(this.selected)
                this.dialogPause = false
                this.alertDialog = false
                //this.$root.$emit('setSelectBnkID', this.selected.bank)
                this.editedIndex = this.DataReturn.indexOf(this.selected)
                this.editedItem = Object.assign({}, this.selected)
                //console.log(this.editedItem.UUID)
                //this.dialogFields = this.editedFields
                //this.dialog = true
                let data = []
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': store.state.CurrentApiKey,
                        'jobUUID': this.editedItem.UUID,
                        'Content-Type': 'application/json'
                    },
                    url: store.state.urlCJ + 'setJobOnPause',
                    data: data

                })
                .then((response) => {
                    //console.log(response)
                    if(response.data == "OK"){
                        this.doAlertMessage(true,'info','mdi-information-outline','Выполнено','Процесс остановлен')
                    }
                    else
                    {
                        this.doAlertMessage(true,'error','mdi-alert-circle-outline','Ошибка','Что то пошло не так, обратитесь к разработчику')
                    }
                    this.refreshData();
                })
                .catch((response) => {
                    //console.log(response)
                    let err = Object.assign({}, response)
                    console.log('error', err.response);
                                    
                    if(err.response.status == '488')
                    {
                        this.$router.push('/')
                    }
                });
            }
            else
            {
                //console.log(this.alert)
                this.doAlertMessage(true,'error','mdi-alert-circle-outline','Внимание','Для остановки процесса, нужно выбрать запись в таблице.')
                //this.alertText = 'Для редактирования, нужно выбрать запись в таблице.'
                //this.alert = true
            }
        },
        isButtonDisabledLite() {
            const hasLiteAccess  = store.getters.LiteAccess !== 0;
            const hasUnlimitedAccess  = store.getters.UnlimitedAccess === 1;
            const dateAccess  = store.getters.DateAccess;
            
            let isDateValid = false;
            if (dateAccess !== null) {
                const currentDate = new Date();
                const accessDate = new Date(dateAccess);
                
                // Сравниваем только год, месяц и день
                isDateValid = 
                accessDate.getFullYear() > currentDate.getFullYear() || 
                (accessDate.getFullYear() === currentDate.getFullYear() && 
                (accessDate.getMonth() > currentDate.getMonth() || 
                    (accessDate.getMonth() === currentDate.getMonth() && 
                    accessDate.getDate() >= currentDate.getDate())));
                
                //console.log(isDateValid)

                //console.log(`${accessDate.getFullYear()}-${String(accessDate.getMonth() + 1).padStart(2, '0')}-${String(accessDate.getDate()).padStart(2, '0')}`);
                //console.log(`${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`);
            }

            // Доступ открыт, если есть Lite доступ И (есть безлимитный доступ ИЛИ дата действительна)
            const hasAccess = hasLiteAccess && (hasUnlimitedAccess || isDateValid);

            //console.log('hasLiteAccess:', hasLiteAccess);
            //console.log('hasUnlimitedAccess:', hasUnlimitedAccess);
            //console.log('isDateValid:', isDateValid);
            //console.log('hasAccess:', hasAccess);

            return hasAccess;
        },
        isButtonDisabledPro() {
            const hasProAccess  = store.getters.ProAccess !== 0;
            const hasUnlimitedAccess  = store.getters.UnlimitedAccess === 1;
            const dateAccess  = store.getters.DateAccess;
            
            let isDateValid = false;
            if (dateAccess !== null) {
                const currentDate = new Date();
                const accessDate = new Date(dateAccess);
                
                // Сравниваем только год, месяц и день
                isDateValid = 
                accessDate.getFullYear() > currentDate.getFullYear() || 
                (accessDate.getFullYear() === currentDate.getFullYear() && 
                (accessDate.getMonth() > currentDate.getMonth() || 
                    (accessDate.getMonth() === currentDate.getMonth() && 
                    accessDate.getDate() >= currentDate.getDate())));
                
                //console.log(isDateValid)

                //console.log(`${accessDate.getFullYear()}-${String(accessDate.getMonth() + 1).padStart(2, '0')}-${String(accessDate.getDate()).padStart(2, '0')}`);
                //console.log(`${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`);
            }

            // Доступ открыт, если есть Lite доступ И (есть безлимитный доступ ИЛИ дата действительна)
            const hasAccess = hasProAccess && (hasUnlimitedAccess || isDateValid);

            //console.log('hasProAccess:', hasProAccess);
            //console.log('hasUnlimitedAccess:', hasUnlimitedAccess);
            //console.log('isDateValid:', isDateValid);
            //console.log('hasAccess:', hasAccess);

            return hasAccess;
        },
        resumeJobByUID () {
            let hasAccess = false;
            if(this.isValidValue(this.selected)) {
                if(this.selected.intLitePro == 0) { hasAccess = this.isButtonDisabledLite() }
                if(this.selected.intLitePro == 1) { hasAccess = this.isButtonDisabledPro() }

                if(hasAccess){
                    this.editedIndex = this.DataReturn.indexOf(this.selected)
                    this.editedItem = Object.assign({}, this.selected)
                    //console.log(this.editedItem)
                    if(this.editedItem.intJobActive == 'НЕТ'){
                        this.dialogResume = true
                    }
                    else
                    {
                        this.doAlertMessage(true,'error','mdi-alert-circle-outline','Внимание','Для запуска процесса, нужно выбрать процесс который остановлен!')
                    }
                }
                else
                {
                    if(this.selected.intLitePro == 0) { this.doAlertMessage(true,'info','mdi-information-outline','Нет доступа','Требуется активная подписка Lite!') }
                    if(this.selected.intLitePro == 1) { this.doAlertMessage(true,'info','mdi-information-outline','Нет доступа','Требуется активная подписка Pro!') }
                }
            }
            else
            {
                //console.log(this.alert)
                this.doAlertMessage(true,'error','mdi-alert-circle-outline','Внимание','Для запуска процесса, нужно выбрать запись в таблице.')
                //this.alertText = 'Для удаления, нужно выбрать запись в таблице.'
                //this.alert = true
            }
        },
        
        editJobByUID(){
            let hasAccess = false;
            //console.log(this.isValidValue(this.selected))
            if(this.isValidValue(this.selected)) {
                if(this.selected.intLitePro == 0) { hasAccess = this.isButtonDisabledLite() }
                if(this.selected.intLitePro == 1) { hasAccess = this.isButtonDisabledPro() }

                if(hasAccess){
                    this.editedIndex = this.DataReturn.indexOf(this.selected)
                    this.editedItem = Object.assign({}, this.selected)
                    //console.log(this.editedItem)
                    if(this.editedItem.intJobActive == 'НЕТ'){
                        this.$store.commit('SetProjectUUID', this.editedItem.UUID)
                        this.$store.commit('SetProjectCOPY', 0)
                        this.$store.commit('SetProjectEDIT', 1)
                        //
                        if(this.editedItem.strJobName.trim()=='Заказы из Геткурса в Гугл таблицы'){
                            this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                            //this.$router.push('/mainScreen/createGC_Sheet_Deals_Exp_Edit_Copy')
                          
                            this.currentForm = null;  // Сначала сбрасываем текущую форму
                            this.$nextTick(() => {
                                this.currentForm = UPcreateGC_Sheet_Deals_Exp_Edit_Copy;
                                this.formKey += 1;
                                this.editdialog = true;
                            });
                        }
                        if(this.editedItem.strJobName.trim()=='Платежи из Геткурса в Гугл таблицы'){
                            this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                            //this.$router.push('/mainScreen/createGC_Sheet_Payments_Exp_Edit_Copy')

                            this.currentForm = null;  // Сначала сбрасываем текущую форму
                            this.$nextTick(() => {
                                this.currentForm = UPcreateGC_Sheet_Payments_Exp_Edit_Copy;
                                this.formKey += 1;
                                this.editdialog = true;
                            });
                        }
                        if(this.editedItem.strJobName.trim()=='Пользователи из Геткурса в Гугл таблицы'){
                            this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                            //this.$router.push('/mainScreen/createGC_Sheet_Users_Exp_Edit_Copy')

                            this.currentForm = null;  // Сначала сбрасываем текущую форму
                            this.$nextTick(() => {
                                this.currentForm = UPcreateGC_Sheet_Users_Exp_Edit_Copy;
                                this.formKey += 1;
                                this.editdialog = true;
                            });
                        }
                        if(this.editedItem.strJobName.trim()=='Отчеты Bizon365 в Гугл таблицы'){
                            this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                            //this.$router.push('/mainScreen/createBZ365_Sheet_Webinar_Exp_Edit_Copy')

                            this.currentForm = null;  // Сначала сбрасываем текущую форму
                            this.$nextTick(() => {
                                this.currentForm = UPcreateBZ365_Sheet_Webinar_Exp_Edit_Copy;
                                this.formKey += 1;
                                this.editdialog = true;
                            });
                        }
                        if(this.editedItem.strJobName.trim()=='PG Заказы из Геткурса в Базу Данных'){
                            this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                            //this.$router.push('/mainScreen/createGC_PG_DB_Deals_Exp_Edit_Copy')

                            this.currentForm = null;  // Сначала сбрасываем текущую форму
                            this.$nextTick(() => {
                                this.currentForm = UPcreateGC_PG_DB_Deals_Exp_Edit_Copy;
                                this.formKey += 1;
                                this.editdialog = true;
                            });
                        }
                        if(this.editedItem.strJobName.trim()=='PG Платежи из Геткурса в Базу Данных'){
                            this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                            //this.$router.push('/mainScreen/createGC_PG_DB_Payments_Exp_Edit_Copy')

                            this.currentForm = null;  // Сначала сбрасываем текущую форму
                            this.$nextTick(() => {
                                this.currentForm = UPcreateGC_PG_DB_Payments_Exp_Edit_Copy;
                                this.formKey += 1;
                                this.editdialog = true;
                            });
                        }
                        if(this.editedItem.strJobName.trim()=='PG Пользователи из Геткурса в Базу Данных'){
                            this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                            //this.$router.push('/mainScreen/createGC_PG_DB_Users_Exp_Edit_Copy')

                            this.currentForm = null;  // Сначала сбрасываем текущую форму
                            this.$nextTick(() => {
                                this.currentForm = UPcreateGC_PG_DB_Users_Exp_Edit_Copy;
                                this.formKey += 1;
                                this.editdialog = true;
                            });
                        }
                        if(this.editedItem.strJobName.trim()=='PG Отчеты Bizon365 в Базу Данных'){
                            this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                            //this.$router.push('/mainScreen/createBZ365_PG_DB_Webinar_Exp_Edit_Copy')
                            
                            this.currentForm = null;  // Сначала сбрасываем текущую форму
                            this.$nextTick(() => {
                                this.currentForm = UPcreateBZ365_PG_DB_Webinar_Exp_Edit_Copy;
                                this.formKey += 1;
                                this.editdialog = true;
                            });
                        }
                        if(this.editedItem.strJobName.substring(0,19).trim()=='PostgreSql функция:'){
                            this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                            //this.$router.push('/mainScreen/createFX_PG_Run_Edit_Copy')

                            this.currentForm = null;  // Сначала сбрасываем текущую форму
                            this.$nextTick(() => {
                                this.currentForm = UPcreateFX_PG_Run_Edit_Copy;
                                this.formKey += 1;
                                this.editdialog = true;
                            });
                        }
                        if(this.editedItem.strJobName.substring(0,26).trim()=='PostgreSql в Гугл таблицы:'){
                            this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                            //this.$router.push('/mainScreen/createPG_Sheet_Exp_Edit_Copy')

                            this.currentForm = null;  // Сначала сбрасываем текущую форму
                            this.$nextTick(() => {
                                this.currentForm = UPcreatePG_Sheet_Exp_Edit_Copy;
                                this.formKey += 1;
                                this.editdialog = true;
                            });
                        }
                        if(this.editedItem.strJobName.trim()=='PG Звонки из Online PBX в Базу Данных'){
                            this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                            //this.$router.push('/mainScreen/createPBX_PG_DB_Calls_Exp_Edit_Copy')

                            this.currentForm = null;  // Сначала сбрасываем текущую форму
                            this.$nextTick(() => {
                                this.currentForm = UPcreatePBX_PG_DB_Calls_Exp_Edit_Copy;
                                this.formKey += 1;
                                this.editdialog = true;
                            });
                        }
                        if(this.editedItem.strJobName.trim()=='PG Рассылки из Геткурса в Базу Данных'){
                            this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                            //this.$router.push('/mainScreen/createGC_PG_DB_MailList_Exp_Edit_Copy')

                            this.currentForm = null;  // Сначала сбрасываем текущую форму
                            this.$nextTick(() => {
                                this.currentForm = UPcreateGC_PG_DB_MailList_Exp_Edit_Copy;
                                this.formKey += 1;
                                this.editdialog = true;
                            });
                        }
                        if(this.editedItem.strJobName.trim()=='YD Отчеты из ЯндексДирект в Базу Данных'){
                            this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                            //this.$router.push('/mainScreen/createYANDEX_PG_DB_Direct_Exp_Edit_Copy')

                            this.currentForm = null;  // Сначала сбрасываем текущую форму
                            this.$nextTick(() => {
                                this.currentForm = UPcreateYANDEX_PG_DB_Direct_Exp_Edit_Copy;
                                this.formKey += 1;
                                this.editdialog = true;
                            });
                        }
                        if(this.editedItem.strJobName.trim()=='VK Отчеты из ВК(1.0) в Базу Данных'){
                            this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                            //this.$router.push('/mainScreen/createVK1_PG_DB_Ads_Exp_Edit_Copy')

                            this.currentForm = null;  // Сначала сбрасываем текущую форму
                            this.$nextTick(() => {
                                this.currentForm = UPcreateVK1_PG_DB_Ads_Exp_Edit_Copy;
                                this.formKey += 1;
                                this.editdialog = true;
                            });
                        }
                        if(this.editedItem.strJobName.trim()=='VK Отчеты из ВК(2.0) в Базу Данных'){
                            this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                            //this.$router.push('/mainScreen/createVK2_PG_DB_Ads_Exp_Edit_Copy')

                            this.currentForm = null;  // Сначала сбрасываем текущую форму
                            this.$nextTick(() => {
                                this.currentForm = UPcreateVK2_PG_DB_Ads_Exp_Edit_Copy;
                                this.formKey += 1;
                                this.editdialog = true;
                            });
                        }
                        if(this.editedItem.strJobName.trim()=='GADS Отчеты из Google в Базу Данных'){
                            this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                            //this.$router.push('/mainScreen/createGADS_PG_DB_Exp_Edit_Copy')

                            this.currentForm = null;  // Сначала сбрасываем текущую форму
                            this.$nextTick(() => {
                                this.currentForm = UPcreateGADS_PG_DB_Exp_Edit_Copy;
                                this.formKey += 1;
                                this.editdialog = true;
                            });
                        }
                        if(this.editedItem.strJobName.trim()=='PostgreSql Builder в Гугл таблицы'){
                            this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                            //this.$router.push('/mainScreen/createPG_Sheet_Exp_Sql')

                            this.currentForm = null;  // Сначала сбрасываем текущую форму
                            this.$nextTick(() => {
                                this.currentForm = UPcreatePG_Sheet_Exp_Sql;
                                this.formKey += 1;
                                this.editdialog = true;
                            });
                        }
                        if(this.editedItem.strJobName.trim()=='PG Активные пользователи из Геткурса в Базу Данных'){
                            this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                            //this.$router.push('/mainScreen/createGC_PG_DB_UserActive_Exp')

                            this.currentForm = null;  // Сначала сбрасываем текущую форму
                            this.$nextTick(() => {
                                this.currentForm = UPcreateGC_PG_DB_UserActive_Exp;
                                this.formKey += 1;
                                this.editdialog = true;
                            });
                        }
                        if(this.editedItem.strJobName.trim()=='PG Контакты из AmoCrm в Базу Данных'){
                            this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                            //this.$router.push('/mainScreen/createAmo_PG_DB_Contacts_Exp_Edit_Copy')

                            this.currentForm = null;  // Сначала сбрасываем текущую форму
                            this.$nextTick(() => {
                                this.currentForm = UPcreateAmo_PG_DB_Contacts_Exp_Edit_Copy;
                                this.formKey += 1;
                                this.editdialog = true;
                            });
                        }
                        if(this.editedItem.strJobName.trim()=='PG Сделки из AmoCrm в Базу Данных'){
                            this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                            //this.$router.push('/mainScreen/createAmo_PG_DB_Leads_Exp_Edit_Copy')

                            this.currentForm = null;  // Сначала сбрасываем текущую форму
                            this.$nextTick(() => {
                                this.currentForm = UPcreateAmo_PG_DB_Leads_Exp_Edit_Copy;
                                this.formKey += 1;
                                this.editdialog = true;
                            });
                        }
                        if(this.editedItem.strJobName.trim()=='PG Конструктор пользователей из Геткурса в БД'){
                            this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                            //this.$router.push('/mainScreen/createGC_PG_DB_UsersBuilder_Exp')

                            this.currentForm = null;  // Сначала сбрасываем текущую форму
                            this.$nextTick(() => {
                                this.currentForm = UPcreateGC_PG_DB_UsersBuilder_Exp;
                                this.formKey += 1;
                                this.editdialog = true;
                            });
                        }
                    }
                    else
                    {
                        this.doAlertMessage(true,'error','mdi-alert-circle-outline','Внимание','Для Редактирования процесса, нужно выбрать процесс который остановлен!')
                    }
                }
                else
                {
                    if(this.selected.intLitePro == 0) { this.doAlertMessage(true,'info','mdi-information-outline','Нет доступа','Требуется активная подписка Lite!') }
                    if(this.selected.intLitePro == 1) { this.doAlertMessage(true,'info','mdi-information-outline','Нет доступа','Требуется активная подписка Pro!') }
                }
            }
            else
            {
                //console.log(this.alert)
                this.doAlertMessage(true,'error','mdi-alert-circle-outline','Внимание','Для запуска процесса, нужно выбрать запись в таблице.')
                //this.alertText = 'Для удаления, нужно выбрать запись в таблице.'
                //this.alert = true
            }
        },
        copyJobByUID () {
            let hasAccess = false;
            if(this.isValidValue(this.selected)) {
                if(this.selected.intLitePro == 0) { hasAccess = this.isButtonDisabledLite() }
                if(this.selected.intLitePro == 1) { hasAccess = this.isButtonDisabledPro() }

                if(hasAccess){
                    this.editedIndex = this.DataReturn.indexOf(this.selected)
                    this.editedItem = Object.assign({}, this.selected)
                    //console.log(this.editedItem)
                    this.$store.commit('SetProjectUUID', this.editedItem.UUID)
                    this.$store.commit('SetProjectCOPY', 1)
                    this.$store.commit('SetProjectEDIT', 0)
                    //
                    /* if(this.editedItem.strJobName.trim()=='Заказы из Геткурса в Гугл таблицы'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createGC_Sheet_Deals_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.trim()=='Платежи из Геткурса в Гугл таблицы'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createGC_Sheet_Payments_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.trim()=='Пользователи из Геткурса в Гугл таблицы'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createGC_Sheet_Users_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.trim()=='Отчеты Bizon365 в Гугл таблицы'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createBZ365_Sheet_Webinar_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.trim()=='PG Заказы из Геткурса в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createGC_PG_DB_Deals_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.trim()=='PG Платежи из Геткурса в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createGC_PG_DB_Payments_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.trim()=='PG Пользователи из Геткурса в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createGC_PG_DB_Users_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.trim()=='PG Отчеты Bizon365 в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createBZ365_PG_DB_Webinar_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.substring(0,19).trim()=='PostgreSql функция:'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createFX_PG_Run_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.substring(0,26).trim()=='PostgreSql в Гугл таблицы:'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createPG_Sheet_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.trim()=='PG Звонки из Online PBX в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createPBX_PG_DB_Calls_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.trim()=='PG Рассылки из Геткурса в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createGC_PG_DB_MailList_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.trim()=='YD Отчеты из ЯндексДирект в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createYANDEX_PG_DB_Direct_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.trim()=='VK Отчеты из ВК(1.0) в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createVK1_PG_DB_Ads_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.trim()=='VK Отчеты из ВК(2.0) в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createVK2_PG_DB_Ads_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.trim()=='GADS Отчеты из Google в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createGADS_PG_DB_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.trim()=='PostgreSql Builder в Гугл таблицы'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createPG_Sheet_Exp_Sql')
                    }
                    if(this.editedItem.strJobName.trim()=='PG Активные пользователи из Геткурса в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createGC_PG_DB_UserActive_Exp')
                    }
                    if(this.editedItem.strJobName.trim()=='PG Контакты из AmoCrm в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createAmo_PG_DB_Contacts_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.trim()=='PG Сделки из AmoCrm в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createAmo_PG_DB_Leads_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.trim()=='PG Конструктор пользователей из Геткурса в БД'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createGC_PG_DB_UsersBuilder_Exp')
                    } */

                    if(this.editedItem.strJobName.trim()=='Заказы из Геткурса в Гугл таблицы'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createGC_Sheet_Deals_Exp_Edit_Copy')
                        
                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateGC_Sheet_Deals_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='Платежи из Геткурса в Гугл таблицы'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createGC_Sheet_Payments_Exp_Edit_Copy')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateGC_Sheet_Payments_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='Пользователи из Геткурса в Гугл таблицы'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createGC_Sheet_Users_Exp_Edit_Copy')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateGC_Sheet_Users_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='Отчеты Bizon365 в Гугл таблицы'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createBZ365_Sheet_Webinar_Exp_Edit_Copy')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateBZ365_Sheet_Webinar_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='PG Заказы из Геткурса в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createGC_PG_DB_Deals_Exp_Edit_Copy')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateGC_PG_DB_Deals_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='PG Платежи из Геткурса в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createGC_PG_DB_Payments_Exp_Edit_Copy')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateGC_PG_DB_Payments_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='PG Пользователи из Геткурса в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createGC_PG_DB_Users_Exp_Edit_Copy')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateGC_PG_DB_Users_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='PG Отчеты Bizon365 в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createBZ365_PG_DB_Webinar_Exp_Edit_Copy')
                        
                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateBZ365_PG_DB_Webinar_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.substring(0,19).trim()=='PostgreSql функция:'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createFX_PG_Run_Edit_Copy')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateFX_PG_Run_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.substring(0,26).trim()=='PostgreSql в Гугл таблицы:'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createPG_Sheet_Exp_Edit_Copy')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreatePG_Sheet_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='PG Звонки из Online PBX в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createPBX_PG_DB_Calls_Exp_Edit_Copy')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreatePBX_PG_DB_Calls_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='PG Рассылки из Геткурса в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createGC_PG_DB_MailList_Exp_Edit_Copy')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateGC_PG_DB_MailList_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='YD Отчеты из ЯндексДирект в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createYANDEX_PG_DB_Direct_Exp_Edit_Copy')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateYANDEX_PG_DB_Direct_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='VK Отчеты из ВК(1.0) в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createVK1_PG_DB_Ads_Exp_Edit_Copy')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateVK1_PG_DB_Ads_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='VK Отчеты из ВК(2.0) в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createVK2_PG_DB_Ads_Exp_Edit_Copy')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateVK2_PG_DB_Ads_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='GADS Отчеты из Google в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createGADS_PG_DB_Exp_Edit_Copy')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateGADS_PG_DB_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='PostgreSql Builder в Гугл таблицы'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createPG_Sheet_Exp_Sql')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreatePG_Sheet_Exp_Sql;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='PG Активные пользователи из Геткурса в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createGC_PG_DB_UserActive_Exp')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateGC_PG_DB_UserActive_Exp;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='PG Контакты из AmoCrm в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createAmo_PG_DB_Contacts_Exp_Edit_Copy')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateAmo_PG_DB_Contacts_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='PG Сделки из AmoCrm в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createAmo_PG_DB_Leads_Exp_Edit_Copy')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateAmo_PG_DB_Leads_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='PG Конструктор пользователей из Геткурса в БД'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createGC_PG_DB_UsersBuilder_Exp')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateGC_PG_DB_UsersBuilder_Exp;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                }
                else
                {
                    if(this.selected.intLitePro == 0) { this.doAlertMessage(true,'info','mdi-information-outline','Нет доступа','Требуется активная подписка Lite!') }
                    if(this.selected.intLitePro == 1) { this.doAlertMessage(true,'info','mdi-information-outline','Нет доступа','Требуется активная подписка Pro!') }
                }
            }
            else
            {
                //console.log(this.alert)
                this.doAlertMessage(true,'error','mdi-alert-circle-outline','Внимание','Для запуска процесса, нужно выбрать запись в таблице.')
                //this.alertText = 'Для удаления, нужно выбрать запись в таблице.'
                //this.alert = true
            }
        },
        copyOneTimeJobByUID () {
            let hasAccess = false;
            if(this.isValidValue(this.selected)) {
                if(this.selected.intLitePro == 0) { hasAccess = this.isButtonDisabledLite() }
                if(this.selected.intLitePro == 1) { hasAccess = this.isButtonDisabledPro() }

                if(hasAccess){
                    this.editedIndex = this.DataReturn.indexOf(this.selected)
                    this.editedItem = Object.assign({}, this.selected)
                    //console.log(this.editedItem)
                    this.$store.commit('SetProjectUUID', this.editedItem.UUID)
                    this.$store.commit('SetProjectCOPY', 2)
                    this.$store.commit('SetProjectEDIT', 0)
                    //
                    /* if(this.editedItem.strJobName.trim()=='Заказы из Геткурса в Гугл таблицы'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createGC_Sheet_Deals_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.trim()=='Платежи из Геткурса в Гугл таблицы'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createGC_Sheet_Payments_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.trim()=='Пользователи из Геткурса в Гугл таблицы'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createGC_Sheet_Users_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.trim()=='Отчеты Bizon365 в Гугл таблицы'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createBZ365_Sheet_Webinar_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.trim()=='PG Заказы из Геткурса в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createGC_PG_DB_Deals_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.trim()=='PG Платежи из Геткурса в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createGC_PG_DB_Payments_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.trim()=='PG Пользователи из Геткурса в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createGC_PG_DB_Users_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.trim()=='PG Отчеты Bizon365 в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createBZ365_PG_DB_Webinar_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.substring(0,19).trim()=='PostgreSql функция:'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createFX_PG_Run_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.substring(0,26).trim()=='PostgreSql в Гугл таблицы:'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createPG_Sheet_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.trim()=='PG Звонки из Online PBX в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createPBX_PG_DB_Calls_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.trim()=='PG Рассылки из Геткурса в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createGC_PG_DB_MailList_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.trim()=='YD Отчеты из ЯндексДирект в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createYANDEX_PG_DB_Direct_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.trim()=='VK Отчеты из ВК(1.0) в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createVK1_PG_DB_Ads_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.trim()=='VK Отчеты из ВК(2.0) в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createVK2_PG_DB_Ads_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.trim()=='GADS Отчеты из Google в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createGADS_PG_DB_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.trim()=='PostgreSql Builder в Гугл таблицы'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createPG_Sheet_Exp_Sql')
                    }
                    if(this.editedItem.strJobName.trim()=='PG Активные пользователи из Геткурса в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createGC_PG_DB_UserActive_Exp')
                    }
                    if(this.editedItem.strJobName.trim()=='PG Контакты из AmoCrm в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createAmo_PG_DB_Contacts_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.trim()=='PG Сделки из AmoCrm в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createAmo_PG_DB_Leads_Exp_Edit_Copy')
                    }
                    if(this.editedItem.strJobName.trim()=='PG Конструктор пользователей из Геткурса в БД'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        this.$router.push('/mainScreen/createGC_PG_DB_UsersBuilder_Exp')
                    } */

                    if(this.editedItem.strJobName.trim()=='Заказы из Геткурса в Гугл таблицы'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createGC_Sheet_Deals_Exp_Edit_Copy')
                        
                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateGC_Sheet_Deals_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='Платежи из Геткурса в Гугл таблицы'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createGC_Sheet_Payments_Exp_Edit_Copy')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateGC_Sheet_Payments_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='Пользователи из Геткурса в Гугл таблицы'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createGC_Sheet_Users_Exp_Edit_Copy')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateGC_Sheet_Users_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='Отчеты Bizon365 в Гугл таблицы'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createBZ365_Sheet_Webinar_Exp_Edit_Copy')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateBZ365_Sheet_Webinar_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='PG Заказы из Геткурса в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createGC_PG_DB_Deals_Exp_Edit_Copy')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateGC_PG_DB_Deals_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='PG Платежи из Геткурса в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createGC_PG_DB_Payments_Exp_Edit_Copy')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateGC_PG_DB_Payments_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='PG Пользователи из Геткурса в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createGC_PG_DB_Users_Exp_Edit_Copy')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateGC_PG_DB_Users_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='PG Отчеты Bizon365 в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createBZ365_PG_DB_Webinar_Exp_Edit_Copy')
                        
                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateBZ365_PG_DB_Webinar_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.substring(0,19).trim()=='PostgreSql функция:'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createFX_PG_Run_Edit_Copy')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateFX_PG_Run_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.substring(0,26).trim()=='PostgreSql в Гугл таблицы:'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createPG_Sheet_Exp_Edit_Copy')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreatePG_Sheet_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='PG Звонки из Online PBX в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createPBX_PG_DB_Calls_Exp_Edit_Copy')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreatePBX_PG_DB_Calls_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='PG Рассылки из Геткурса в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createGC_PG_DB_MailList_Exp_Edit_Copy')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateGC_PG_DB_MailList_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='YD Отчеты из ЯндексДирект в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createYANDEX_PG_DB_Direct_Exp_Edit_Copy')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateYANDEX_PG_DB_Direct_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='VK Отчеты из ВК(1.0) в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createVK1_PG_DB_Ads_Exp_Edit_Copy')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateVK1_PG_DB_Ads_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='VK Отчеты из ВК(2.0) в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createVK2_PG_DB_Ads_Exp_Edit_Copy')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateVK2_PG_DB_Ads_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='GADS Отчеты из Google в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createGADS_PG_DB_Exp_Edit_Copy')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateGADS_PG_DB_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='PostgreSql Builder в Гугл таблицы'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createPG_Sheet_Exp_Sql')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreatePG_Sheet_Exp_Sql;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='PG Активные пользователи из Геткурса в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createGC_PG_DB_UserActive_Exp')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateGC_PG_DB_UserActive_Exp;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='PG Контакты из AmoCrm в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createAmo_PG_DB_Contacts_Exp_Edit_Copy')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateAmo_PG_DB_Contacts_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='PG Сделки из AmoCrm в Базу Данных'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createAmo_PG_DB_Leads_Exp_Edit_Copy')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateAmo_PG_DB_Leads_Exp_Edit_Copy;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                    if(this.editedItem.strJobName.trim()=='PG Конструктор пользователей из Геткурса в БД'){
                        this.$store.commit('SetProjectNAME', this.editedItem.strJobName.trim())
                        //this.$router.push('/mainScreen/createGC_PG_DB_UsersBuilder_Exp')

                        this.currentForm = null;  // Сначала сбрасываем текущую форму
                        this.$nextTick(() => {
                            this.currentForm = UPcreateGC_PG_DB_UsersBuilder_Exp;
                            this.formKey += 1;
                            this.editdialog = true;
                        });
                    }
                }
                else
                {
                    if(this.selected.intLitePro == 0) { this.doAlertMessage(true,'info','mdi-information-outline','Нет доступа','Требуется активная подписка Lite!') }
                    if(this.selected.intLitePro == 1) { this.doAlertMessage(true,'info','mdi-information-outline','Нет доступа','Требуется активная подписка Pro!') }
                }
            }
            else
            {
                //console.log(this.alert)
                this.doAlertMessage(true,'error','mdi-alert-circle-outline','Внимание','Для запуска процесса, нужно выбрать запись в таблице.')
                //this.alertText = 'Для удаления, нужно выбрать запись в таблице.'
                //this.alert = true
            }
        },
        doResumeJobByUID () {
            //console.log(this.selected)
            if(this.isValidValue(this.selected)) {
                //console.log(this.selected)
                this.dialogResume = false
                this.alertDialog = false
                //this.$root.$emit('setSelectBnkID', this.selected.bank)
                this.editedIndex = this.DataReturn.indexOf(this.selected)
                this.editedItem = Object.assign({}, this.selected)
                //console.log(this.editedItem.UUID)
                //this.dialogFields = this.editedFields
                //this.dialog = true
                let data = []
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': store.state.CurrentApiKey,
                        'jobUUID': this.editedItem.UUID,
                        'Content-Type': 'application/json'
                    },
                    url: store.state.urlCJ + 'resumeAddNewJobFromDB',
                    data: data

                })
                .then((response) => {
                    //console.log(response)
                    if(response.data == "OK"){
                        this.doAlertMessage(true,'info','mdi-information-outline','Выполнено','Процесс запущен')
                    }
                    else
                    {
                        this.doAlertMessage(true,'error','mdi-alert-circle-outline','Ошибка','Что то пошло не так, обратитесь к разработчику')
                    }
                    this.refreshData();
                })
                .catch((response) => {
                    //console.log(response)
                    let err = Object.assign({}, response)
                    console.log('error', err.response);
                                    
                    if(err.response.status == '488')
                    {
                        this.$router.push('/')
                    }
                });
            }
            else
            {
                //console.log(this.alert)
                this.doAlertMessage(true,'error','mdi-alert-circle-outline','Внимание','Для запуска процесса, нужно выбрать запись в таблице.')
                //this.alertText = 'Для редактирования, нужно выбрать запись в таблице.'
                //this.alert = true
            }
        },
        async save () {
           
            //console.log(this.editedIndex)
            
        },
        handleWindowSizeChange() {
            // Update data properties with 
            // new window dimensions
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;

            // Additional logic based on
            // window size changes
            //console.log('Window dimensions changed:', this.windowWidth, this.windowHeight);
        },
    },
    mounted() {
        const settingCronProjectUUID = {
            strCronProjectUUID: this.$store.getters.CronProjectUUID,
        }
        this.$store.dispatch('loadjobProjects', settingCronProjectUUID)
        //this.dataItems = this.$store.getters.settings
        this.$root.$on('closeAlertMessage', (text) => {
            this.alertMessage.alertMessageShow = text
        })
        this.dialogFields = this.addFields

        //this.dataGrid.collapseAll();

        //console.log(this.windowHeight)

        window.addEventListener('resize', this.handleWindowSizeChange);
        
    },
    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'Добавить' : 'Редактировать'
        },
        DataReturn() {
            return this.$store.getters.jobProjects
        },
        loadingF(){
            return this.$store.getters.loading2
        }
    },
    beforeDestroy() {
        // Remove the event listener when
        // the component is destroyed
        window.removeEventListener('resize', this.handleWindowSizeChange);
    },
  }
</script>
<style>

</style>