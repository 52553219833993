import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import user from './user'
import common from './common'
import jobProjects from './jobProjects'
import pgFunctions from './pgFunctions'
import pgFunctionsArguments from './pgFunctionsArguments'
import pgViews from './pgViews'
import pgTables from './pgTables'
import pgGoogleServiceAccounts from './pgGoogleServiceAccounts'
import pgColumnsTables from './pgColumnsTables'
import telegramSubscribers from './telegramSubscribers'
import otherProjects from './otherProjects'
import googleOauth2Worker from './googleOauth2Worker'
import webHooksSettings from './webHooksSettings'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    urlAN: process.env.URLAN,
    urlCJ: process.env.URLCJ,
    //urlCJ: 'http://localhost:8480/cronNode/',
    ApiKey: '',
    UserMail: '',
    IsAdmin: false,
    IsSchoolAdmin: false,
    UserProjID: '',
    UserID: '',
    dateStart: '',
    dateEnd: '',
    UserName: 'Yana-Vegana',
    panelMini: false,
    ProjectUUID: '',
    ProjectCOPY: '',
    ProjectEDIT: '',
    ProjectNAME: '',
    LiteAccess: 0,
    ProAccess: 0,
    UnlimitedAccess: 0,
    DateLastPay: undefined,
    DateAccess: undefined,
    GlobalProjectName: '',
    CurrentApiKey: '',
    CronProjectUUID: '',
  },
  mutations: {
    SetPanelMini(state, panelMini) {
      state.panelMini = panelMini
    },
    SetUserMail(state, UserMail) {
      state.UserMail = UserMail
    },
    SetAdmin(state, IsAdmin) {
      state.IsAdmin = IsAdmin
    },
    SetSchoolAdmin(state, IsSchoolAdmin) {
      state.IsSchoolAdmin = IsSchoolAdmin
    },
    SetUserProjID(state, UserProjID) {
      state.UserProjID = UserProjID
    },
    SetUserID(state, UserID) {
      state.UserID = UserID
    },
    SetDateEnd(state, dateEnd) {
      state.dateEnd = dateEnd
    },
    SetDateStart(state, dateStart) {
      state.dateStart = dateStart
    },
    SetUserName(state, UserName) {
      state.UserName = UserName
    },
    SetProjectUUID(state, ProjectUUID) {
      state.ProjectUUID = ProjectUUID
    },
    SetProjectCOPY(state, ProjectCOPY) {
      state.ProjectCOPY = ProjectCOPY
    },
    SetProjectEDIT(state, ProjectEDIT) {
      state.ProjectEDIT = ProjectEDIT
    },
    SetProjectNAME(state, ProjectNAME) {
      state.ProjectNAME = ProjectNAME
    },
    SetLiteAccess(state, LiteAccess) {
      state.LiteAccess = LiteAccess
    },
    SetProAccess(state, ProAccess) {
      state.ProAccess = ProAccess
    },
    SetUnlimitedAccess(state, UnlimitedAccess) {
      state.UnlimitedAccess = UnlimitedAccess
    },
    SetDateLastPay(state, DateLastPay) {
      state.DateLastPay = DateLastPay
    },
    SetDateAccess(state, DateAccess) {
      state.DateAccess = DateAccess
    },
    SetGlobalProjectName(state, GlobalProjectName) {
      state.GlobalProjectName = GlobalProjectName
    },
    SetCurrentApiKey(state, CurrentApiKey) {
      state.CurrentApiKey = CurrentApiKey
    },
    SetCronProjectUUID(state, CronProjectUUID) {
      state.CronProjectUUID = CronProjectUUID
    },
  },
  getters:{
    UserMail: state => state.UserMail,
    IsAdmin: state => state.IsAdmin,
    ProjectUUID: state => state.ProjectUUID,
    ProjectCOPY: state => state.ProjectCOPY,
    ProjectEDIT: state => state.ProjectEDIT,
    ProjectNAME: state => state.ProjectNAME,
    IsSchoolAdmin: state => state.IsSchoolAdmin,
    UserProjID: state => state.UserProjID,
    UserID: state => state.UserID,
    dateStart: state => state.dateStart,
    dateEnd: state => state.dateEnd,
    UserName: state => state.UserName,
    panelMini: state => state.panelMini,
    LiteAccess: state => state.LiteAccess,
    ProAccess: state => state.ProAccess,
    UnlimitedAccess: state => state.UnlimitedAccess,
    DateLastPay: state => state.DateLastPay,
    DateAccess: state => state.DateAccess,
    GlobalProjectName: state => state.GlobalProjectName,
    CurrentApiKey: state => state.CurrentApiKey,
    CronProjectUUID: state => state.CronProjectUUID,
  },
  actions: {
  },
  modules: {
    user,
    common,
    jobProjects,
    pgFunctions,
    pgFunctionsArguments,
    pgViews,
    pgTables,
    pgGoogleServiceAccounts,
    pgColumnsTables,
    telegramSubscribers,
    otherProjects,
    googleOauth2Worker,
    webHooksSettings
  },
  plugins: [createPersistedState()]
})
